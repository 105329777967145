import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, Event } from '@angular/router';
import { BrowserService } from 'src/app/services/browser.service';
import { ApiService } from '../../services/api.service';
import { SubjectService } from "../../services/subject.service";


@Component({
  selector: 'app-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: ['./city-select.component.css']
})
export class CitySelectComponent implements OnInit {
  cityList: any = [];
  icontype = 'Normal';
  selectedCity: any = {};
  loading:boolean=false;
  setCity = null;
  search_item:string='';
  constructor(@Inject(PLATFORM_ID) private platformId,
    private api: ApiService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private subject: SubjectService,
    private browser:BrowserService,
    private router:Router
    ) {
      this.subject.loader.subscribe(value=>{
        this.loading=value;
      })
    if (this.browser.getLocalValue('city')) {
      this.selectedCity = JSON.parse(this.browser.getLocalValue('city'));
      //this.citySelect(JSON.parse(this.browser.getLocalValue('city')), 'constructor');
    }

    // iconRegistry.addSvgIcon('Kuwait_Active', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/Country/Kuwait_Active.svg'))
    // iconRegistry.addSvgIcon('Kuwait', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/Country/Kuwait_Normal.svg'))
    // iconRegistry.addSvgIcon('Bahrain_Active', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/Country/Bahrain_Active.svg'))
    // iconRegistry.addSvgIcon('Bahrain', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/Country/Bahrain_Normal.svg'))
    // iconRegistry.addSvgIcon('Riyadh_Active', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/Country/Riyadh_Normal.svg'))
    // iconRegistry.addSvgIcon('Riyadh', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/Country/Riyadh_Active.svg'))
    // iconRegistry.addSvgIcon('Dubai', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/Country/Dubai_Normal.svg'))
    // iconRegistry.addSvgIcon('Dubai_Active', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/Country/Dubai_Active.svg'))
    // iconRegistry.addSvgIcon('Sharajh_Active', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/Country/Sharajh_Active.svg'))
    // iconRegistry.addSvgIcon('other', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/Country/other_country.svg'));
    this.subject.cityList.subscribe(cname => {
      if (typeof cname == 'string') {
        this.cityList = JSON.parse(cname);
      }else{
        this.cityList = cname
      }
    });
  }
  citySelect(cities, from) {
    this.selectedCity = cities;
    if (isPlatformBrowser(this.platformId)) {
      this.loading = false;
      this.router.navigateByUrl(`${this.selectedCity.slug}/reports/mi-dashboard`);
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          this.api.citySelect(this.selectedCity, 'view');
      }
    });
}

}
